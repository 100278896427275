export enum MenuType {
    Home = "Home",
    Exhibition = "Exhibition",
    Guideline = "Guideline",
    Reference = "Reference",
    Receipt = "Receipt",
    Validation = "Validation",
    Notice = "Notice",
    QnA = "QnA",
    Copilot = "Copilot",
    Verification = "Verification",
    Prize = "Prize",
}