import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import locale from 'antd/locale/ko_KR'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'swiper/css'
import { CreateQnA } from './pages/CreateQnA'
import { Guideline } from './pages/Guideline'
import { Home } from './pages/Home'
import { Notice } from './pages/Notice'
import { NoticeDetail } from './pages/NoticeDetail'
import { PrivacyTerm } from './pages/PrivacyTerm'
import { QnA } from './pages/QnA'
import { QnADetail } from './pages/QnADetail'
import { Receipt } from './pages/Receipt'
import { Reference } from './pages/Reference'
import './styles/index.scss'
import { Copilot } from './pages/Copilot'
import { Verification } from './pages/Verification'
import { Prize } from './pages/Prize'

const App = () => {
    return (
        <ConfigProvider
            locale={locale}
            theme={{
                token: {
                    colorPrimary: '#0a71ce',
                    borderRadius: 4,
                    colorBgContainer: '#fdfeff',
                },
                components: {
                    Menu: {
                        horizontalLineHeight: '62px',
                        itemSelectedBg: '#e2e2e2'
                    },
                    Tabs: {
                        cardGutter: 6,
                    },
                    Table: {
                        rowHoverBg: '#f7f7f7',
                        rowExpandedBg: '#f7f7f7'
                    },
                    Select: {
                        optionSelectedBg: '#e2e2e2',
                    }
                }
            }}
        >
            <BrowserRouter>
                <Routes>
                    {/* <Route path="/exhibitions" element={<Exhibition />} /> */}
                    <Route path="/guidelines" element={<Guideline />} />
                    <Route path="/copilots" element={<Copilot />} />
                    <Route path="/references" element={<Reference />} />
                    <Route path="/receipts" element={<Receipt />} />

                    <Route path="/prize" element={<Prize />} />

                    <Route path="/notices" element={<Notice />} />
                    <Route path="/notices/:id" element={<NoticeDetail />} />

                    <Route path="/qna" element={<QnA />} />
                    <Route path="/qna/create" element={<CreateQnA />} />
                    <Route path="/qna/:id" element={<QnADetail />} />

                    <Route path="/verifications" element={<Verification />} />

                    <Route path="/views/privacy-term" element={<PrivacyTerm />} />

                    <Route path='*' element={<Home />} />
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    )
}

export default App