import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Card, Col, Flex, Layout, Row, Space, Typography } from 'antd'
import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReferenceModal } from '../modal/ReferenceModal'
import { ReferenceModel } from '../model/ReferenceModel'
import { MenuType } from '../type/MenuType'
import { Header } from './layout/Header'
import { MainLayout } from './layout/MainLayout'

export const Reference: FunctionComponent = () => {
    const navigate = useNavigate()
    const [reference, setReference] = useState<ReferenceModel.IReferenceModel | null>()

    const videos: { title: string, items: Array<ReferenceModel.IReferenceModel> } = {
        title: '영상자료',
        items: [
            {
                name: '휘용 협업 영상(마인크래프트)',
                preview: 'https://img.youtube.com/vi/8VjRXhumUHY/maxresdefault.jpg',
                url: 'https://www.youtube.com/watch?v=8VjRXhumUHY',
            },
            {
                name: '제페토 영상',
                preview: 'https://img.youtube.com/vi/_CdIFgRG16I/maxresdefault.jpg',
                description: '네이버 제페토에서 지금 미래 고속도로 드라이빙을 떠나보세요!',
                url: 'https://www.youtube.com/watch?v=_CdIFgRG16I',
            }
        ],
    }

    const images: { title: string, items: Array<ReferenceModel.IReferenceModel> } = {
        title: '추가자료',
        items: [
            {
                name: '미래고속도로 전체 조감도',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/0.png'
            },
            {
                name: '초장대교량(고덕대교)',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/1.png'
            },
            {
                name: '스마트 물류센터',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/2.png'
            },
            {
                name: '한강터널(TBM공법)',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/3.png'
            },
            {
                name: '복합환승 모빌리티 서비스',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/4.png'
            },
            {
                name: 'UAM 승하차시설',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/5.png'
            },
            {
                name: '지하 고속도로',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/6.png'
            },
            {
                name: '비탈면 첨단관리',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/7.png'
            },
            {
                name: '노변기지국',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/8.png'
            },
            {
                name: '유지관리 자동화(AI 도로살얼음 시스템)',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/9.png'
            },
            {
                name: '전기차 무선충전, 압전포장, 조립식 도로, 친환경포장',
                preview: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/references/10.png'
            },
        ],
    }

    return (
        <MainLayout menuType={MenuType.Reference}>
            <Header />
            <Row>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: '참고자료',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                    <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Space style={{ height: '100%' }}>
                            <Typography.Title level={3}>참고자료</Typography.Title>
                            <Flex align='flex-end' style={{ height: 32 }}>
                                <Typography.Title level={5} style={{ fontWeight: 400, color: '#999' }}>자세한 내용은 항목을 클릭해서 확인해보세요!</Typography.Title>
                            </Flex>
                        </Space>
                    </Col>
                    <Col span={24} style={{ padding: 0, marginTop: 12, }}>
                        <Card
                            bordered
                            size='small'
                        >
                            <Row gutter={[12, 12]} style={{ marginBottom: 52, }}>
                                <Col span={24}>
                                    <Typography.Title level={4} style={{}}>
                                        {videos.title}
                                    </Typography.Title>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[12, 12]}>
                                        {videos.items.map((r: ReferenceModel.IReferenceModel) => {
                                            return (
                                                <Col
                                                    className="winner-container"
                                                    {...{ xs: 24, sm: 24, md: 12, lg: 12, }}
                                                >
                                                    <Typography.Link
                                                        href={r.url}
                                                        target='_blank'
                                                    >
                                                        <img
                                                            width="100%"
                                                            height="auto"
                                                            src={r.preview}
                                                            alt='미리보기 이미지'
                                                        />
                                                        <Typography.Title
                                                            level={4}
                                                            style={{ fontSize: 18, color: '#0088CC', fontWeight: 800, marginTop: 12, textAlign: 'center' }}
                                                        >
                                                            {r.name}
                                                        </Typography.Title>
                                                    </Typography.Link>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[12, 12]} style={{ marginBottom: 52, }}>
                                <Col span={24}>
                                    <Typography.Title level={4} style={{}}>
                                        {images.title}
                                    </Typography.Title>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[24, 24]}>
                                        {images.items.map((r: ReferenceModel.IReferenceModel) => {
                                            return (
                                                <Col
                                                    className="winner-container"
                                                    {...{ xs: 24, sm: 24, md: 8, lg: 8, }}
                                                    style={{ height: '100%' }}
                                                >
                                                    <Typography.Link
                                                        onClick={() => {
                                                            setReference(r)
                                                        }}
                                                    >
                                                        <img
                                                            width="100%"
                                                            height="100"
                                                            style={{
                                                                objectFit: 'cover',
                                                                minHeight: 200,
                                                            }}
                                                            alt='미리보기 이미지'
                                                            src={r.preview}
                                                        />
                                                        <Typography.Title
                                                            level={4}
                                                            style={{ fontSize: 18, color: '#0088CC', fontWeight: 800, marginTop: 12, textAlign: 'center' }}
                                                        >
                                                            {r.name}
                                                        </Typography.Title>
                                                    </Typography.Link>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
            {reference && (
                <ReferenceModal
                    show={reference != null}
                    reference={reference}
                    onClosed={() => { setReference(null) }}
                />
            )}
        </MainLayout>
    )
}