import { HomeOutlined, NotificationOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Flex, Layout, Row, Space, Table, Typography } from 'antd'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuType } from '../type/MenuType'
import { MainLayout } from './layout/MainLayout'

export const Copilot: FunctionComponent = () => {
    const navigate = useNavigate()

    const renderReciept = (
        children: React.ReactNode,
    ) => {
        return (
            <Col span={24}>
                <Row>
                    <Col {...{ xs: 0, sm: 2, md: 2, lg: 1 }}>
                        <Flex
                            justify='start'
                            style={{
                                paddingTop: 2,
                            }}
                        >
                            <NotificationOutlined style={{ fontSize: '26px', color: '#08c' }} />
                        </Flex>
                    </Col>
                    <Col {...{ xs: 24, sm: 22, md: 22, lg: 23 }}>
                        {children}
                    </Col>
                </Row>
            </Col>
        )
    }

    return (
        <MainLayout menuType={MenuType.Copilot}>
            <Row style={{ backgroundColor: '#fff', marginTop: 70, }}>
                <Col span={24}>
                    <Layout.Content className='inner-container'>
                        <img
                            src={'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/copilot_banner.png'}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                minHeight: 160,
                            }}
                            alt='메인 이미지'
                        />
                    </Layout.Content>
                </Col>
            </Row>
            <Row>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: '온라인교육',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                    <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Typography.Title level={3}>
                            Microsoft사와 함께하는 "코파일럿 무료 교육"
                        </Typography.Title>
                    </Col>
                    <Col span={24} style={{ marginTop: 12, }}>
                        <Row
                            gutter={[12, 28]}
                            style={{
                                border: '1px solid #eee',
                                borderRadius: 4,
                                padding: 16,
                            }}
                        >
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>시간</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12 }}>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            - 회당 30분~1시간
                                        </Typography.Text>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>일정</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12 }}>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            - 8/26(월) 오후 2시부터
                                        </Typography.Text>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            - 8/29(목) 오후 2시부터
                                        </Typography.Text>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>방식</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Space size={4} wrap>
                                            <Typography.Text style={{ fontSize: 16, }}>
                                                - 온라인 Teams 교육
                                            </Typography.Text>
                                            <Typography.Text type={'danger'} style={{ fontSize: 16, }}>
                                                {'(교육 당일 13시 30분에 링크 오픈 예정)'}
                                            </Typography.Text>
                                        </Space>
                                        <Table
                                            pagination={false}
                                            dataSource={[
                                                { key: '2024년 8월 26일', link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZjU4MDVmZTItY2MxNC00YjVlLTg3ODYtYmRmNWUyYmNkNjhk%40thread.v2/0?context=%7b%22Tid%22%3a%22f754124d-f841-4b68-aec4-092f484be5e1%22%2c%22Oid%22%3a%224e021554-4f92-48f4-8b3d-1e6678a9e4a5%22%7d', },
                                                { key: '2024년 8월 29일', link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OTlhOWEyNWUtOWNhZi00MzlmLWE5ODYtYzg4YzMxZThjOTMy%40thread.v2/0?context=%7b%22Tid%22%3a%22f754124d-f841-4b68-aec4-092f484be5e1%22%2c%22Oid%22%3a%224e021554-4f92-48f4-8b3d-1e6678a9e4a5%22%7d', },
                                            ]}
                                            columns={[
                                                { title: '일정', dataIndex: 'key', key: 'key', align: 'center', width: '50%', },
                                                {
                                                    title: '링크', dataIndex: 'link', key: 'link', align: 'center', width: '50%',
                                                    render: (v: string) => {
                                                        return (
                                                            <Typography.Link
                                                                href={v}
                                                                target={'_blank'}
                                                            >
                                                                {'[교육 링크]'}
                                                            </Typography.Link>
                                                        )
                                                    }
                                                },
                                            ]}
                                        />
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            {'*교육 자료는 공지사항 탭에서 다운로드 가능합니다.'}
                                        </Typography.Text>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>대상 및 인원</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12 }}>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            - 희망자 누구나(참여인원 제한 없음)
                                        </Typography.Text>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>교육 내용</Typography.Title>
                                    <Space direction='vertical' size={2} style={{ marginTop: 12, }}>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            {'1) Copilot 및 DALL-E 소개'}
                                        </Typography.Text>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            {'2) MA(Microsoft Account) 계정 생성 및 Copilot 로그인 안내'}
                                        </Typography.Text>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            {'3) Copilot을 활용한 이미지 생성 실습'}
                                        </Typography.Text>
                                        <Space style={{ marginTop: 12, }}>
                                            <Typography.Text type='secondary'>
                                                {'* 원활한 교육 및 실습을 위해 사전에 Copilot 프로그램 설치 후 참석하시길 권장합니다.'}
                                            </Typography.Text>
                                        </Space>
                                    </Space>
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}