import { AxiosRequestConfig } from "axios";
import AxiosContext from "../contexts/AxiosContext";

export const QnAService = {
    create: async (payload: any) => {
        try {
            const { data, status } = await AxiosContext.post(`/highways/qna`, payload);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    qna: async (id: string, payload: any) => {
        try {
            const { data, status } = await AxiosContext.post(`/highways/qna/${id}`, payload);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    search: async (params: any) => {
        try {
            const config: AxiosRequestConfig = {
                params: params
            }

            const { data, status } = await AxiosContext.get(`/highways/qna`, config);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
}