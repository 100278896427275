import { CloseOutlined } from '@ant-design/icons'
import { Col, Flex, Modal, Row, Typography } from "antd"
import { FunctionComponent } from "react"
import { ReferenceModel } from '../model/ReferenceModel'

interface IProps {
    reference: ReferenceModel.IReferenceModel
    show: boolean
    onClosed: () => void
}

export const ReferenceModal: FunctionComponent<IProps> = (props) => {
    const { reference, show, onClosed } = props

    return (
        <Modal
            centered
            open={show}
            closeIcon={false}
            maskClosable={true}
            destroyOnClose={true}
            maskTransitionName=""
            onCancel={() => { onClosed() }}
            footer={null}
            width={'60%'}
            styles={{
                content: {
                    padding: 0,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                }
            }}
            style={{ minWidth: 350 }}
        >
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <Flex justify='end'>
                        <Typography.Link
                            onClick={onClosed}
                        >
                            <CloseOutlined
                                style={{
                                    color: '#fff',
                                    fontSize: 26,
                                }}
                            />
                        </Typography.Link>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Flex align="center">
                        <img
                            width="100%"
                            height="auto"
                            alt='참고 이미지'
                            src={reference.preview}
                        />
                    </Flex>
                </Col>
                <Col span={24} style={{ marginTop: 24, }}>
                    <Typography.Title
                        level={4}
                        style={{ color: '#55c6ff', fontWeight: 800, textAlign: 'center' }}
                    >
                        {reference.name}
                    </Typography.Title>
                </Col>
            </Row>
        </Modal>
    )
}