import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Flex, Layout, Row, Tabs, Typography } from 'antd'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReceiptCheckContent } from '../content/ReceiptCheckContent'
import { MenuType } from '../type/MenuType'
import { ReceiptType } from '../type/ReceiptType'
import { Header } from './layout/Header'
import { MainLayout } from './layout/MainLayout'

export const Receipt: FunctionComponent = () => {
    const navigate = useNavigate()

    return (
        <MainLayout menuType={MenuType.Receipt}>
            <Header />
            <Row>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: '공모전 접수 및 확인',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                    <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Typography.Title level={3}>접수 및 확인</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Row justify={'center'}>
                            <Col {...{ xs: 24, sm: 20, md: 16, lg: 12, xl: 10 }}>
                                <Tabs
                                    type="line"
                                    items={[
                                        ReceiptType.check,
                                    ].map((t) => {
                                        switch (t) {
                                            default:
                                                return {
                                                    label: '공모전 접수 확인',
                                                    key: t,
                                                    children: <ReceiptCheckContent />
                                                }
                                        }
                                    })}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}