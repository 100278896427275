import { HomeOutlined, MenuOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Card, Col, Flex, Form, Input, Layout, message, Row, Space, Spin, Typography } from 'antd'
import { FunctionComponent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Errors } from '../contexts/ErrorContext'
import { QnAModel } from '../model/QnAModel'
import { QnAService } from '../services/QnAService'
import { MenuType } from '../type/MenuType'
import DateUtility from '../utils/DateUtility'
import NumberUtility from '../utils/NumberUtility'
import { Header } from './layout/Header'
import { MainLayout } from './layout/MainLayout'

export const QnADetail: FunctionComponent = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    if (!id) {
        message.error("문의 상세정보를 찾을 수 없습니다.", 2, () => { navigate(-1) })
    }

    const [form] = Form.useForm()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [qna, setQna] = useState<QnAModel.IQnAModel | null>(
        // {
        //     "id": "0ab6d8d8-0d1d-4a8f-86d3-c9a2758b401a",
        //     "nickname": "테스트",
        //     "title": "테스트 제목",
        //     "content": "테스트\n내용\n입니다.",
        //     "answer": "2024-07-31T16:53:05.066",
        //     "viewCount": 0,
        //     "answered": "답변\n내용\n입니다.",
        //     "isAnswered": true,
        //     "created": "2024-07-31T14:53:05.066"
        // }
    )

    const verify = () => {
        grecaptcha.ready(() => {
            grecaptcha
                .execute('6LcPoxoqAAAAAIu-JtMn5QChUUeMW9-mtsCO5NQ5', { action: 'submit' })
                .then(async (token: string) => {
                    detail(token)
                })
        })
    }

    const detail = (token: string) => {
        form.validateFields()
            .then(async (values) => {
                setLoading(true)

                const payload = {
                    ...values,
                    token: token,
                }

                const response = await QnAService.qna(id!!, payload)
                if (response.status === 200) {
                    setQna(response.data.item)
                } else {
                    Errors.AjaxError(response.data)
                }
                setLoading(false)
            })
            .catch((e) => {
                message.error(e.message)
                console.log('error', e.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <MainLayout menuType={MenuType.QnA}>
            <Header />
            <Row>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: 'Q&A',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                    <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Typography.Title level={3}>Q&A</Typography.Title>
                    </Col>
                    {!qna && (
                        <Col span={24} style={{ marginTop: 16, }}>
                            <Row justify={'center'}>
                                <Col {...{ xs: 24, sm: 20, md: 16, lg: 12, xl: 10 }}>
                                    <Card bordered>
                                        <Form form={form} layout="vertical">
                                            <Row gutter={[12, 12]}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="비밀번호"
                                                        name="password"
                                                        style={{ marginBottom: 8 }}
                                                        rules={[{ required: true, message: "비밀번호를 입력하세요" }]}
                                                    >
                                                        <Input.Password
                                                            placeholder="비밀번호를 입력하세요"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Button
                                                        block
                                                        size='large'
                                                        type='primary'
                                                        loading={isLoading}
                                                        onClick={verify}
                                                    >
                                                        문의내용 확인
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Layout.Content>

            {qna && (
                <Layout.Content className='inner-container responsive-container'>
                    <Spin spinning={isLoading}>
                        <Row gutter={[0, 8]} style={{ marginTop: 38, marginLeft: 0, marginRight: 0, }}>
                            <Col span={24}>
                                <Flex justify='end'>
                                    <Button
                                        ghost
                                        type="primary"
                                        loading={isLoading}
                                        icon={<MenuOutlined />}
                                        onClick={() => { navigate('/qna') }}>
                                        목록보기
                                    </Button>
                                </Flex>
                            </Col>
                            <Col span={24}>
                                <Row
                                    gutter={[8, 8]}
                                    justify={'center'}
                                    style={{
                                        paddingTop: 18,
                                        paddingRight: 12,
                                        paddingLeft: 12,
                                        paddingBottom: 12,
                                        backgroundColor: '#f4faff',
                                        borderTop: '2px solid #1c79bf',
                                        marginLeft: 0, marginRight: 0,
                                    }}
                                >
                                    <Col span={24}>
                                        <Flex justify='center'>
                                            <Typography.Title level={4} style={{ textAlign: 'center' }}>{qna?.title}</Typography.Title>
                                        </Flex>
                                    </Col>
                                    <Col span={24}>
                                        <Flex justify='end'>
                                            <Space split={'|'}>
                                                <Typography.Text>{qna?.nickname}</Typography.Text>
                                                <Typography.Text>{`조회: ${NumberUtility.comma(qna?.viewCount ?? 0)}`}</Typography.Text>
                                                <Typography.Text>{DateUtility.date_format(qna?.created)}</Typography.Text>
                                            </Space>
                                        </Flex>
                                    </Col>
                                    <Col span={24}>
                                        <Card
                                            bordered
                                            size='small'
                                        >
                                            <div className="ql-editor">
                                                <div dangerouslySetInnerHTML={{ __html: qna?.content ?? '' }}></div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            {qna?.answer && (
                                <Col span={24}>
                                    <Row
                                        gutter={[8, 8]}
                                        justify={'center'}
                                        style={{
                                            marginTop: 30,
                                            paddingTop: 18,
                                            paddingRight: 12,
                                            paddingLeft: 12,
                                            paddingBottom: 12,
                                            backgroundColor: '#fff8f8',
                                            borderTop: '2px solid #bf1c1c',
                                            marginLeft: 0,
                                            marginRight: 0,
                                        }}
                                    >
                                        <Col span={24}>
                                            <Flex justify='center'>
                                                <Typography.Title level={4} style={{ textAlign: 'center' }}>{'문의하신 내용의 답변입니다.'}</Typography.Title>
                                            </Flex>
                                        </Col>
                                        <Col span={24}>
                                            <Flex justify='end'>
                                                <Space split={'|'}>
                                                    <Typography.Text>{'운영 사무국'}</Typography.Text>
                                                    {qna?.answered && (
                                                        <Typography.Text>{DateUtility.date_format(qna?.answered)}</Typography.Text>
                                                    )}
                                                </Space>
                                            </Flex>
                                        </Col>
                                        <Col span={24}>
                                            <Card
                                                bordered
                                                size='small'
                                            >
                                                <div className="ql-editor">
                                                    <div dangerouslySetInnerHTML={{ __html: qna?.answer ?? '' }}></div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Spin>
                </Layout.Content>
            )}
        </MainLayout>
    )
}