import AxiosContext from "../contexts/AxiosContext";

export const ReceiptService = {
    create: async (payload: any) => {
        try {
            const { data, status } = await AxiosContext.post(`/highways/receipts`, payload);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    edit: async (id: string, payload: any) => {
        try {
            const { data, status } = await AxiosContext.put(`/highways/receipts/${id}`, payload);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    check: async (payload: string) => {
        try {
            const { data, status } = await AxiosContext.post(`/highways/receipts/check`, payload);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    receipt: async (id: string) => {
        try {
            const { data, status } = await AxiosContext.get(`/highways/receipts/${id}`);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
}