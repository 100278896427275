import { HomeOutlined, MenuOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Card, Col, Flex, Form, Input, Layout, message, Modal, Row, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Errors } from '../contexts/ErrorContext'
import { QnAService } from '../services/QnAService'
import { MenuType } from '../type/MenuType'
import { Header } from './layout/Header'
import { MainLayout } from './layout/MainLayout'

export const CreateQnA: FunctionComponent = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const [isLoading, setLoading] = useState<boolean>(false)

    const verify = () => {
        grecaptcha.ready(() => {
            grecaptcha
                .execute('6LcPoxoqAAAAAIu-JtMn5QChUUeMW9-mtsCO5NQ5', { action: 'submit' })
                .then(async (token: string) => {
                    handleCreate(token)
                })
        })
    }

    const handleCreate = (token: string) => {
        form.validateFields()
            .then(async (values) => {
                Modal.confirm({
                    title: '문의사항을 등록 하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    maskTransitionName: '',
                    onOk: async () => {
                        setLoading(true)

                        const payload = {
                            ...values,
                            token: token,
                        }

                        const response = await QnAService.create(payload)
                        if (response.status === 200) {
                            message.success(
                                '문의가 등록되었습니다.',
                                1,
                                () => {
                                    form.resetFields()
                                    navigate('/qna')
                                })
                        } else {
                            Errors.AjaxError(response.data)
                        }
                        setLoading(false)

                    },
                    onCancel: () => {
                        setLoading(false)
                    }
                })
            })
            .catch((e) => {
                message.error(e.message)
                console.log('error', e.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <MainLayout menuType={MenuType.QnA}>
            <Header />
            <Row>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: 'Q&A',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                    <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Typography.Title level={3}>Q&A 작성</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Flex justify='end'>
                            <Button
                                ghost
                                type="primary"
                                loading={isLoading}
                                icon={<MenuOutlined />}
                                onClick={() => { navigate('/qna') }}>
                                목록보기
                            </Button>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Form form={form} layout="vertical">
                            <Row gutter={[0, 8]} justify={'center'} style={{ marginTop: 38, marginLeft: 0, marginRight: 0, }}>
                                <Col {...{ xs: 24, sm: 20, md: 16, lg: 16, xl: 14 }}>
                                    <Card bordered>
                                        <Row gutter={[0, 8]}>
                                            <Col span={24}>
                                                <Row gutter={[8, 8]}>
                                                    <Col {...{ xs: 24, md: 12 }}>
                                                        <Form.Item
                                                            label="닉네임"
                                                            name="nickname"
                                                            style={{ marginBottom: 8 }}
                                                            rules={[{ required: true, message: "닉네임을 입력하세요" }]}
                                                        >
                                                            <Input
                                                                placeholder="닉네임을 입력하세요"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col {...{ xs: 24, md: 12 }}>
                                                        <Form.Item
                                                            label="비밀번호"
                                                            name="password"
                                                            style={{ marginBottom: 8 }}
                                                            rules={[{ required: true, message: "비밀번호를 입력하세요" }]}
                                                        >
                                                            <Input.Password
                                                                placeholder="비밀번호를 입력하세요"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name={"title"}
                                                    label={'제목'}
                                                    style={{ marginBottom: 8 }}
                                                    rules={[{ required: true, message: "제목을 입력하세요" }]}
                                                >
                                                    <Input
                                                        placeholder="제목을 입력하세요"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name={"content"}
                                                    label={'내용'}
                                                    style={{ marginBottom: 8 }}
                                                    rules={[{ required: true, message: "내용을 입력하세요" }]}
                                                >
                                                    <TextArea
                                                        rows={12}
                                                        placeholder="제목을 입력하세요"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Flex justify='end'>
                                                    <Button
                                                        type="primary"
                                                        loading={isLoading}
                                                        onClick={verify}
                                                    >
                                                        작성하기
                                                    </Button>
                                                </Flex>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}