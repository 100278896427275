import { Col, Layout, Row, Typography } from 'antd'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

interface IProps {
    children?: React.ReactNode
}

export const Header: FunctionComponent<IProps> = (props) => {
    const { children } = props
    const navigate = useNavigate()

    const items = [
        {
            link: '/guidelines',
            image: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/0814_bg.jpg',
        },
        {
            link: '/copilots',
            image: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/copilot_banner.png',
        },
    ]

    return (
        <Row style={{ backgroundColor: '#fff', marginTop: 70, }}>
            <Col span={24}>
                <Layout.Content className='inner-container'>
                    <Swiper
                        loop
                        autoplay
                        modules={[Pagination, Autoplay]}
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                    >
                        {items.map((d, idx: number) => {
                            return (
                                <SwiperSlide key={idx}>
                                    <Typography.Link
                                        onClick={() => {
                                            navigate(d.link)
                                        }}
                                    >
                                        <img
                                            src={d.image}
                                            style={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                                minHeight: 160,
                                            }}
                                            alt='메인 이미지'
                                        />
                                    </Typography.Link>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    {children}
                </Layout.Content>
            </Col>
        </Row>
    )
}