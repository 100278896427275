import { Button, Card, Col, Descriptions, Divider, Flex, Form, Input, Layout, Modal, Row, Space, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { Errors } from '../contexts/ErrorContext'
import { ReceiptModel } from '../model/ReceiptModel'
import { ReceiptService } from '../services/ReceiptService'
import DateUtility from '../utils/DateUtility'
import { Utilities } from '../utils/Utilities'

export const ReceiptCheckContent: FunctionComponent = () => {
    const [form] = Form.useForm()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [receipts, setReceipts] = useState<Array<ReceiptModel.IReceiptModel>>([])

    const verify = () => {
        grecaptcha.ready(() => {
            grecaptcha
                .execute('6LcPoxoqAAAAAIu-JtMn5QChUUeMW9-mtsCO5NQ5', { action: 'submit' })
                .then(async (token: string) => {
                    handleCreate(token)
                })
        })
    }

    const handleCreate = (token: string) => {
        form.validateFields()
            .then(async (values) => {
                Modal.confirm({
                    title: '공모전 접수를 확인하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    centered: true,
                    maskTransitionName: '',
                    onOk: async () => {
                        setLoading(true)

                        const payload = {
                            ...values,
                            token: token,
                        }

                        const response = await ReceiptService.check(payload)
                        if (response.status === 200) {
                            setReceipts(response.data.item ?? [])
                        } else {
                            Errors.AjaxError(response.data)
                        }
                        setLoading(false)
                    },
                    onCancel: () => {
                        setLoading(false)
                    }
                })
            })
            .catch((e) => {
                console.log('error', e.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (form) {
            form.resetFields()
        }
    }, [form])

    return (
        <Layout.Content className='inner-container'>
            <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                <Col span={24} style={{ padding: 0, marginTop: 12, }}>
                    <Card
                        bordered
                    >
                        {receipts?.length <= 0 && (
                            <Form form={form} layout="vertical">
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name={"name"}
                                            label={'이름(팀명)'}
                                            style={{ marginBottom: 8 }}
                                            rules={[{ required: true, message: "이름을 입력하세요" }]}
                                        >
                                            <Input
                                                placeholder="이름을 입력하세요"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="비밀번호"
                                            name="password"
                                            style={{ marginBottom: 8 }}
                                            rules={[{ required: true, message: "비밀번호를 입력하세요" }]}
                                        >
                                            <Input.Password
                                                placeholder="비밀번호를 입력하세요"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={"phone"}
                                            label={'핸드폰번호'}
                                            style={{ marginBottom: 8 }}
                                            rules={[{ required: true, message: "핸드폰번호를 입력하세요" }]}
                                        >
                                            <Input
                                                addonBefore={'010'}
                                                placeholder="핸드폰번호를 입력하세요"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Button
                                            block
                                            size='large'
                                            type='primary'
                                            loading={isLoading}
                                            onClick={verify}
                                        >
                                            접수확인
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                        {receipts?.length > 0 && (
                            <>
                                <Flex justify={'center'}>
                                    <Typography.Text strong style={{ color: '#0067c2' }}>
                                        {`${receipts.length}건의 응모가 접수되었습니다!`}
                                    </Typography.Text>
                                </Flex>
                                <Row style={{ marginTop: 24, }}>
                                    {receipts.map((r, idx: number) => {
                                        return (
                                            <Col key={r.id} span={24}>
                                                <Descriptions
                                                    bordered
                                                    layout={'vertical'}
                                                    size="middle"
                                                    column={{ sm: 1, md: 1, lg: 1, xl: 1, xs: 1, xxl: 1, }}
                                                    labelStyle={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <Descriptions.Item label="이름(팀명)">
                                                        <Typography.Text strong>{r?.name}</Typography.Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="연락처">
                                                        <Typography.Text>{r?.phone}</Typography.Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="응모파일">
                                                        <Space size={4} wrap>
                                                            <Typography.Text>{r.filename}</Typography.Text>
                                                            <Typography.Text type='secondary'>{`(${Utilities.convertBytes(r.size ?? 0)?.fit})`}</Typography.Text>
                                                        </Space>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="응모파일">
                                                        <Space size={4} wrap>
                                                            <Typography.Text>{r.receiptFilename}</Typography.Text>
                                                            <Typography.Text type='secondary'>{`(${Utilities.convertBytes(r.receiptSize ?? 0)?.fit})`}</Typography.Text>
                                                        </Space>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="응모일">
                                                        <Typography.Text>{DateUtility.date_format(r?.created)}</Typography.Text>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                {/* <Flex justify='end' style={{ marginTop: 12, }}>
                                                    <Button
                                                        ghost
                                                        type='primary'
                                                        onClick={() => {
                                                            setId(r.id)
                                                        }}
                                                    >
                                                        {'수정'}
                                                    </Button>
                                                </Flex> */}
                                                {(receipts.length - 1) > idx && (
                                                    <Divider style={{ borderBlockStart: '1px solid #0a71ce' }} />
                                                )}
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>
        </Layout.Content>
    )
}