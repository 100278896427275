import { HomeOutlined, NotificationOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Descriptions, Flex, Layout, Row, Space, Table, Typography } from 'antd'
import { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuType } from '../type/MenuType'
import { MainLayout } from './layout/MainLayout'

export const Guideline: FunctionComponent = () => {
    const navigate = useNavigate()

    useEffect(() => {
    }, [])

    const notices = [
        { notice: '출품작은 타 공모전, 대회 등에 출품하거나 공개되지 않은 순수 창작물이여야 하며, 표절, 도용, 모방작으로 인정되는 작품은 당선된 이후라도 무효처리 및 상금 등을 회수 함' },
        { notice: '출품된 작품의 저작권은 출품자(창작자)에게 있으며, 저작권은 수상 후에도 응모자에게 귀속 됨' },
        {
            notice: '주최사는 수상작에 한하여 복제・전송・​배포할 수 있음',
            childrens: [
                '포괄적 이용 권한 : 복제권, 공연권, 공중송신권, 전시원, 배포권, 대여권, 2차적저작물작성원, 동일성유지권',
                '사용 목적 : 언론보도, 홈페이지 / 블로그 / SNS 등 온라인 공개 및 전소, 박람회 / 전시회 및 각종 행사등을 통한 공개 및 전시, 출판물(e - book 포함) 작성 배포, 주최사 온 / 오프라인 홍보물 제작 배포, 내부 시스템 이미지, 광고 기타 사용 범위',
            ]
        },
        { notice: '수상작은 스마트건설엑스포 등 전시에 활용될 수 있음' },
        { notice: '공모에 관한 모든 변경 및 추가 사항은 공모전 접수사이트 등에 공지함' },
        { notice: '모든 출품작은 출품할 때나 주최사가 수상작으로 선정하여 널리 사용할 때 법적 문제가 없어야 함' },
        { notice: '출품자(창작자)는 출품작이 제3자의 저작권, 초상권, 명예훼손등을 침해하지 않도록 주의의무를 다하여야 함' },
        { notice: '출품작에 대한 분쟁 발생 시 모든 책임은 출품자에 있음' },
        { notice: '동일인(팀)의 다수 작품이 수상 범위에 들면 최고 등수를 우선순위로 하여 선정하며, 동일인의 중복 수상은 불가함' },
        { notice: '추후 필요 시 작품의 원본파일 등을 요청할 수 있음' },
        { notice: '작품 영상 제작에 사용된 음악 및 이미지, 폰트는 저작권에 위배되지 않아야 하며, 저작권 침해가 우려될 시 수상에서 제외될 수 있음' },
        { notice: '수상작의 대외 전시 등을 위해 보정 및 편집 등을 할 수 있음' },
    ]

    const renderReciept = (
        children: React.ReactNode,
    ) => {
        return (
            <Col span={24}>
                <Row>
                    <Col {...{ xs: 0, sm: 2, md: 2, lg: 1 }}>
                        <Flex
                            justify='start'
                            style={{
                                paddingTop: 2,
                            }}
                        >
                            <NotificationOutlined style={{ fontSize: '26px', color: '#08c' }} />
                        </Flex>
                    </Col>
                    <Col {...{ xs: 24, sm: 22, md: 22, lg: 23 }}>
                        {children}
                    </Col>
                </Row>
            </Col>
        )
    }

    return (
        <MainLayout menuType={MenuType.Guideline}>
            <Row style={{ backgroundColor: '#fff', marginTop: 70, }}>
                <Col span={24}>
                    <Layout.Content className='inner-container'>
                        <img
                            src={'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/0814_bg.jpg'}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                minHeight: 160,
                            }}
                            alt='메인 이미지'
                        />
                    </Layout.Content>
                </Col>
            </Row>
            <Row>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: '공모요강',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                    <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Typography.Title level={3}>AI로 만드는 미래고속도로 공모전</Typography.Title>
                    </Col>
                    <Col span={24} style={{ marginTop: 12, }}>
                        <Row
                            gutter={[12, 28]}
                            style={{
                                border: '1px solid #eee',
                                borderRadius: 4,
                                padding: 16,
                            }}
                        >
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>응모 주제</Typography.Title>
                                    {/* <Flex
                                        justify='center'
                                        style={{
                                            marginTop: 16,
                                            padding: 20,
                                            backgroundColor: '#0088CC',
                                            borderRadius: 6,
                                        }}
                                    >
                                        <Typography.Title level={3} style={{ color: '#fff' }}>
                                            {'AI로 만드는 미래고속도로 공모전'}
                                        </Typography.Title>
                                    </Flex> */}
                                    <Space direction='vertical' style={{ marginTop: 12 }}>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            - AI로 자유롭게 상상하는 고속도로와 휴게시설의 미래상
                                        </Typography.Text>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>응모 자격</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12 }}>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            - 미래고속도로에 관심 있는 국민 누구나
                                        </Typography.Text>
                                        <Typography.Text style={{ fontSize: 16, }}>
                                            - 5인이내 팀 또는 개인
                                        </Typography.Text>
                                        <Space direction='vertical' size={2}>
                                            <Typography.Text style={{ fontSize: 16, color: '#e37645' }}>
                                                ※ 1인(팀) 당 2작품 이내 접수 가능(동일 작품 중복 접수 불가)
                                            </Typography.Text>
                                            <Typography.Text style={{ fontSize: 16, color: '#e37645' }}>
                                                ※ 참고자료 제공 사진 파일 활용해야 됨
                                            </Typography.Text>
                                        </Space>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>시상 내역</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Typography.Text strong style={{ fontSize: 16, }}>
                                            - 한국도로공사 사장 표창 및 상금 수여 (총 10개팀, 730만원)
                                        </Typography.Text>
                                        <Table
                                            pagination={false}
                                            dataSource={[
                                                { key: '대상', prize: 1, reward: '300만원', achievements: '한국도로공사장상', },
                                                { key: '금상', prize: 2, reward: '각 100만원', achievements: '한국도로공사장상', },
                                                { key: '은상', prize: 3, reward: '각 50만원', achievements: '-', },
                                                { key: '동상', prize: 4, reward: '각 20만원', achievements: '-', },
                                            ]}
                                            columns={[
                                                { title: '구분', dataIndex: 'key', key: 'key', align: 'center', },
                                                { title: '시상 수', dataIndex: 'prize', key: 'prize', align: 'center' },
                                                { title: '상금', dataIndex: 'reward', key: 'reward', align: 'center' },
                                                // { title: '상장', dataIndex: 'achievements', key: 'achievements', align: 'center' },
                                            ]}
                                        />
                                        <Space direction='vertical' size={2}>
                                            <Typography.Text style={{ fontSize: 14, color: '#e37645' }}>
                                                ※ 제세공과금 수상자 부담
                                            </Typography.Text>
                                            <Typography.Text style={{ fontSize: 14, color: '#e37645' }}>
                                                ※ 작품 수준 및 심사 결과에 따라 시상내역은 일부 조정될 수 있음
                                            </Typography.Text>
                                        </Space>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>응모 일정</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Table
                                            pagination={false}
                                            dataSource={[
                                                { key: '접수기간', period: '2024년 8월 19일(월) ~ 9월 18일(수) 자정까지', },
                                                { key: '심사기간', period: '2024년 9월 23일 ~ 9월 25일', },
                                                { key: '의견수렴', period: '2024년 9월 26일 ~ 10월 7일', },
                                                { key: '결과발표', period: '2024년 10월 10일', },
                                                { key: '전 시', period: '2024년 11월 20일 ~ 11월 22일', },
                                            ]}
                                            columns={[
                                                { title: '구분', dataIndex: 'key', key: 'key', align: 'center', },
                                                { title: '기간', dataIndex: 'period', key: 'period', align: 'center' },
                                            ]}
                                        />
                                        <Space direction='vertical' size={2}>
                                            <Typography.Text style={{ fontSize: 14, color: '#e37645' }}>
                                                ※ 상기일정은 공모전 운영 상황에 따라 변경 될 수 있습니다.
                                            </Typography.Text>
                                        </Space>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>제출 내용</Typography.Title>
                                    <Space direction='vertical' size={2} style={{ marginTop: 12, }}>
                                        <Typography.Text strong style={{ fontSize: 16, }}>
                                            {'- 생성형AI 프로그램으로 제작한 이미지 또는 영상'}
                                        </Typography.Text>
                                        <Typography.Text strong style={{ fontSize: 16, }}>
                                            - 표절 방지를 위한 프롬프트(명령어)별 스크린샷 제출 및 보완과정 서술 필수
                                        </Typography.Text>
                                    </Space>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Table
                                            pagination={false}
                                            dataSource={[
                                                { key: '작품 소개', description: '제작 의도, 제작 과정 및 보완과정 등 자유롭게 서술', },
                                                { key: '제작과정 스크린샷', description: '프롬프트(명령어)별 스크린샷 필수', },
                                                { key: '신청 서류', description: '별도 서식 작성 후 첨부하여 응모\n(서식은 접수 화면에서 다운로드 가능)', },
                                            ]}
                                            columns={[
                                                { title: '내용', dataIndex: 'key', key: 'key', align: 'center', },
                                                {
                                                    title: '기간', dataIndex: 'description', key: 'description', align: 'center',
                                                    render: (v: string) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    whiteSpace: 'pre-wrap',
                                                                }}>
                                                                {v}
                                                            </div>
                                                        )
                                                    }
                                                },
                                            ]}
                                        />
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>제출 규격</Typography.Title>
                                    <Descriptions
                                        bordered
                                        layout={'vertical'}
                                        size="middle"
                                        column={{ sm: 1, md: 1, lg: 1, xl: 1, xs: 1, xxl: 1, }}
                                        style={{
                                            marginTop: 12,
                                        }}
                                        labelStyle={{
                                            textAlign: 'center',
                                            // fontWeight: 700,
                                        }}
                                    >
                                        <Descriptions.Item label="공통">
                                            <Space direction='vertical' wrap>
                                                <Typography.Text>{'- 생성형 AI를 활용하여 프롬프트(명령어)를 입력하여 제작되는 제작물에 대한 생성 과정 화면 스크린샷 첨부'}</Typography.Text>
                                                <Typography.Text>{'- 생성형 AI를 통한 이미지가 아닌 이미 생성된 이미지는 무효'}</Typography.Text>
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="이미지">
                                            <Space direction='vertical' wrap>
                                                <Space wrap>
                                                    <Typography.Text strong>{'분량'}</Typography.Text>
                                                    <Typography.Text>{'이미지 1컷'}</Typography.Text>
                                                </Space>
                                                <Space wrap>
                                                    <Typography.Text strong>{'크기'}</Typography.Text>
                                                    <Typography.Text>{'1920px * 1920px 이상 사이즈'}</Typography.Text>
                                                </Space>
                                                <Space wrap>
                                                    <Typography.Text strong>{'형식'}</Typography.Text>
                                                    <Typography.Text>{'300dpi 이상 해상도 / jpg, png'}</Typography.Text>
                                                </Space>
                                                <Space wrap>
                                                    <Typography.Text strong>{'용량'}</Typography.Text>
                                                    <Typography.Text>{'20MB 이하'}</Typography.Text>
                                                </Space>
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="동영상">
                                            <Space direction='vertical' wrap>
                                                <Space wrap>
                                                    <Typography.Text strong>{'분량'}</Typography.Text>
                                                    <Typography.Text>{'60초 이내'}</Typography.Text>
                                                </Space>
                                                <Space wrap>
                                                    <Typography.Text strong>{'형식'}</Typography.Text>
                                                    <Typography.Text>{'1920px * 1680px 이상 사이즈(HD 이상) / 동영상 파일(avi, mp4)'}</Typography.Text>
                                                </Space>
                                                <Space wrap>
                                                    <Typography.Text strong>{'용량'}</Typography.Text>
                                                    <Typography.Text>{'1GB 이하'}</Typography.Text>
                                                </Space>
                                                <Space wrap>
                                                    <Typography.Text strong>{'기타'}</Typography.Text>
                                                    <Typography.Text>{'​영상 편집에 한하여 에디팅툴 사용 가능'}</Typography.Text>
                                                </Space>
                                            </Space>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>접수 방법</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Space direction='vertical' size={2}>
                                            <Typography.Text strong style={{ fontSize: 16, }}>
                                                공모전 홈페이지 접수
                                            </Typography.Text>
                                        </Space>
                                    </Space>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Space wrap>
                                            <Typography.Text style={{ fontSize: 16, }}>
                                                - 파일 제목 :
                                            </Typography.Text>
                                            <Typography.Text style={{ fontSize: 16, }}>
                                                이미지_작품명 또는 동영상_작품명
                                            </Typography.Text>
                                        </Space>
                                        <Space direction='vertical' wrap>
                                            <Typography.Text style={{ fontSize: 16, }}>
                                                - 필수사항 기재
                                            </Typography.Text>
                                            <Typography.Paragraph>
                                                <ul style={{ lineHeight: '28px', marginBottom: 0, fontSize: 16, }}>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        {`①  사용한 생성형AI 프로그램`}
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        {`② 사용한 프롬프트(명령어)`}
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        {`③ 최종 결과물 출품작의 의미 및 작품 해설`}
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        {`④ 수정 · 보완한 경우 원본 생성 이미지 제출 필수`}
                                                    </li>
                                                    <li style={{ listStyleType: 'none' }}>
                                                        {`⑤ 생성형AI 프로그램 내 작성한 프롬프트 증빙자료(스크린샷) 제출 필수`}
                                                    </li>
                                                </ul>
                                            </Typography.Paragraph>
                                        </Space>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>심사 기준</Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Table
                                            pagination={false}
                                            dataSource={[
                                                { key: '창의성', description: '독특하고 창의적인 아이디어 및 프롬프트 활용', point: 40, },
                                                { key: '완성도', description: '출품작의 콘텐츠 완성도 및 품질', point: 30, },
                                                { key: '내용적합성', description: '고속도로 관련 시설과의 연계성\n(참고자료의 시설물 활용 여부 등)', point: 20, },
                                                { key: '활용가능성', description: '현업 적용 가능 여부 및 콘텐츠로서의 활용도', point: 10, },
                                                { key: '', description: '총계', point: 100, },
                                            ]}
                                            columns={[
                                                { title: '평가항목', dataIndex: 'key', key: 'key', align: 'center', },
                                                {
                                                    title: '평가내용',
                                                    dataIndex: 'description',
                                                    key: 'description',
                                                    align: 'center',
                                                    render: (v: string) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    whiteSpace: 'pre-wrap',
                                                                    fontWeight: v === '총계' ? 700 : 400
                                                                }}>
                                                                {v}
                                                            </div>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: '점수',
                                                    dataIndex: 'point',
                                                    key: 'point',
                                                    align: 'center',
                                                    render: (v: number) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    whiteSpace: 'pre-wrap',
                                                                    fontWeight: v >= 100 ? 700 : 400
                                                                }}>
                                                                {`${v}점`}
                                                            </div>
                                                        )
                                                    }
                                                },
                                            ]}
                                        />
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>
                                        유의 사항
                                    </Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Typography.Paragraph>
                                            <ul>
                                                {notices.map((n) => {
                                                    return (
                                                        <li key={n.notice}>
                                                            <Typography.Text
                                                            >
                                                                {n.notice}
                                                            </Typography.Text>
                                                            {n.childrens && (
                                                                <Typography.Paragraph>
                                                                    <ul style={{ marginBottom: 8, }}>
                                                                        {n.childrens?.map((c) => {
                                                                            return (
                                                                                <li key={c} style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                                                                                    <Typography.Text
                                                                                        type='secondary'
                                                                                    >
                                                                                        {c}
                                                                                    </Typography.Text>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </Typography.Paragraph>
                                                            )}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Typography.Paragraph>
                                    </Space>
                                </>
                            )}
                            {renderReciept(
                                <>
                                    <Typography.Title level={4}>
                                        문의 사항
                                    </Typography.Title>
                                    <Space direction='vertical' style={{ marginTop: 12, width: '100%' }}>
                                        <Typography.Text strong style={{ fontSize: 16, }}>{'공모전 운영사무국'}</Typography.Text>
                                        <Space wrap>
                                            <Typography.Text style={{ fontSize: 16, }}>{'공식 이메일:'}</Typography.Text>
                                            <Typography.Link
                                                href={`mailto:koreaex.ai@gmail.com`}
                                                style={{ fontSize: 16, }}
                                            >
                                                {'koreaex.ai@gmail.com'}
                                            </Typography.Link>
                                        </Space>
                                    </Space>
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}