import { Col, Row } from "antd"
import { FunctionComponent } from "react"

export const PrivacyTerm: FunctionComponent = () => {
    return (
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <div
                    className="ql-editor"
                // dangerouslySetInnerHTML={{ __html: content }} 
                />
            </Col>
        </Row>
    )
}