import { CloseOutlined } from '@ant-design/icons'
import { Checkbox, Col, Form, Modal, Row, Typography } from "antd"
import dayjs from 'dayjs'
import { FunctionComponent, useState } from "react"
import { useNavigate } from 'react-router-dom'
import PrizeAsset from '../assets/prize_banner.jpg'
import LocalStorageUtility from '../utils/LocalStorageUtility'

interface IProps {
    image?: string
    show: boolean
    onClosed: () => void
}

export const BannerModal: FunctionComponent<IProps> = (props) => {
    const { image, show, onClosed } = props
    const navigate = useNavigate()

    // does not show again
    const [dnsa, setDnsa] = useState<boolean>(false)

    const handleClose = () => {
        if (dnsa) {
            LocalStorageUtility.set('banner', { created: dayjs() })
        }

        onClosed()
    }

    return (
        <Modal
            centered
            open={show}
            closeIcon={false}
            maskClosable={false}
            destroyOnClose={true}
            maskTransitionName=""
            onCancel={() => { onClosed() }}
            footer={null}
            styles={{
                content: {
                    padding: 0,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                }
            }}
            style={{
                minWidth: 350,
            }}
            width={760}
        >
            <Row gutter={[0, 8]}>
                <Col
                    span={24}
                // style={{ cursor: 'pointer', }}
                // onClick={() => {
                //     navigate('/prize')
                //     onClosed()
                // }}
                >
                    <Typography.Link
                        href='https://www.youtube.com/watch?v=qmCh9GrvhfA'
                        target='_blank'
                    >
                        <img
                            width="100%"
                            height="auto"
                            alt='배너 이미지'
                            src={PrizeAsset}
                        />
                    </Typography.Link>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="dnsa"
                    >
                        <Checkbox
                            checked={dnsa}
                            onClick={() => {
                                setDnsa(!dnsa)
                            }}
                        >
                            <Typography.Text style={{ color: '#fff' }}>
                                오늘 더 이상 보지않기
                            </Typography.Text>
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <div
                style={{
                    position: 'absolute',
                    top: -40,
                    right: 0,
                }}
            >
                <Typography.Link
                    onClick={handleClose}
                >
                    <CloseOutlined
                        style={{
                            color: '#fff',
                            fontSize: 28,
                        }}
                    />
                </Typography.Link>
            </div>
        </Modal>
    )
}