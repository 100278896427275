import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Flex, Layout, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuType } from '../type/MenuType'
import LocalStorageUtility from '../utils/LocalStorageUtility'
import { MainLayout } from './layout/MainLayout'
import { BannerModal } from '../modal/BannerModal'

export const Home: FunctionComponent = () => {
    const navigate = useNavigate()

    const [showBannerModal, setShowBannerModal] = useState<boolean>(false)

    useEffect(() => {
        const banner = LocalStorageUtility.get('banner')
        if (banner) {
            const b = JSON.parse(banner)

            const target = dayjs(b?.created)
            const now = dayjs(`${dayjs().format('YYYY-MM-DD')} 00:00:00`)

            if (target.isBefore(now)) {
                setShowBannerModal(true)
            }
        } else {
            setShowBannerModal(true)
        }
    }, [])

    return (
        <MainLayout menuType={MenuType.Home}>
            {/* <Header /> */}
            <Row>
                <Col
                    span={24}
                    style={{
                        height: 50,
                        marginTop: 70,
                        // borderBottom: '1px solid #eee',
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: '공모내용',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                    {/* <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Typography.Title level={3}>공모 내용</Typography.Title>
                    </Col> */}
                    <Col span={24} style={{ marginTop: 12, }}>
                        <Row
                            gutter={[12, 28]}
                            style={{
                                // border: '1px solid #eee',
                                // borderRadius: 4,
                                // padding: 16,
                            }}
                        >
                            <img
                                src={'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/assets/contest_description.png'}
                                style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                    // minHeight: 675,
                                }}
                                alt='한국도로공사 AI 공모전'
                            />
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>

            <BannerModal
                show={showBannerModal}
                onClosed={() => {
                    setShowBannerModal(false)
                }}
            />
        </MainLayout>
    )
}