export class Utilities {
    static isMobile(): boolean {
        return /Mobi|Android/i.test(navigator.userAgent);
    }

    static isAndroid(): boolean {
        return /Android/i.test(navigator.userAgent);
    }

    static convertBytes(value: number) {
        if (value < 0) {
            return {
                fit: '0Byte',
                byte: 0,
                kb: 0,
                mb: 0,
                gb: 0
            }
        }

        const byte = Math.round(value)
        const kb = Math.round(value / 1024)
        const mb = Math.round(kb / 1024)
        const gb = Math.round(mb / 1024)

        let fit = `${byte}byte`
        if (gb > 0) {
            fit = `${gb}GB`
        } else if (gb <= 0 && mb > 0) {
            fit = `${mb}MB`
        } else if (gb <= 0 && mb <= 0 && kb > 0) {
            fit = `${kb}KB`
        }

        return {
            fit: fit,
            byte: byte,
            kb: kb,
            mb: mb,
            gb: gb
        }
    }
}