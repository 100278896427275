import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Flex, Image, Layout, Row, Typography } from 'antd'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import PrizeAsset from '../assets/prize1.png'
import PrizeBanner from '../assets/prize_banner.jpg'
import { MenuType } from '../type/MenuType'
import { MainLayout } from './layout/MainLayout'

export const Prize: FunctionComponent = () => {
    const navigate = useNavigate()

    return (
        <MainLayout menuType={MenuType.Prize}>
            <Row style={{ marginTop: 90, }}>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: '2024 수상작',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row style={{ minHeight: window.innerHeight * 0.7 }} align={'middle'}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', padding: 24 }}>
                        <Typography.Link
                            href='https://www.youtube.com/watch?v=qmCh9GrvhfA'
                            target='_blank'
                        >
                            <Image
                                alt={'수상작'}
                                preview={false}
                                src={PrizeBanner}
                                style={{ maxHeight: (window.innerHeight) }}
                            />
                        </Typography.Link>
                    </Col>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', padding: 24 }}>
                        <Image
                            alt={'수상작'}
                            preview={false}
                            src={PrizeAsset}
                            style={{ maxHeight: (window.innerHeight) }}
                        />
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}