import { Col, Flex, Image, Layout, Row, Space, Typography } from "antd"
import { FunctionComponent } from "react"

export const Footer: FunctionComponent = () => {
    return (
        <footer className="footer" style={{ paddingTop: 24, paddingBottom: 16, }}>
            <Layout.Content className='inner-container'>
                <Row justify="end">
                    <Col {...{ xs: 0, md: 0, lg: 24 }}>
                        <Row gutter={[24, 24]} style={{ marginTop: 32, }}>
                            <Col span={24}>
                                <Row align={"middle"} style={{ height: '100%' }}>
                                    <Col span={12} className="sns">
                                        <Flex justify="start" align="center">
                                            <Space size={4} direction="vertical">
                                                <Typography.Paragraph style={{ paddingBottom: 8, }}>
                                                    <Typography.Title level={4}>
                                                        AI로 만드는 미래고속도로 공모전
                                                    </Typography.Title>
                                                </Typography.Paragraph>
                                                {/* <Typography.Link
                                                    href="tel: 025383602"
                                                >
                                                    <Typography.Text type='secondary'>
                                                        +82 02.538.3602
                                                    </Typography.Text>
                                                </Typography.Link> */}
                                                <Typography.Text>
                                                    {/* <Typography.Text type='secondary'>
                                                        contact@thinkingwolf.co.kr
                                                    </Typography.Text> */}
                                                    <Typography.Link
                                                        type="secondary"
                                                        href={`mailto:koreaex.ai@gmail.com`}
                                                        style={{ fontSize: 16, }}
                                                    >
                                                        {'koreaex.ai@gmail.com'}
                                                    </Typography.Link>
                                                </Typography.Text>
                                                <Typography.Text type='secondary'>서울특별시 강남구 테헤란로 82길 15, 디아이타워 14층</Typography.Text>

                                                <Typography.Paragraph style={{ marginTop: 12, }}>
                                                    <Typography.Text type='secondary'>copyright © ThinkingWolf. All rights reserved.</Typography.Text>
                                                </Typography.Paragraph>
                                            </Space>
                                        </Flex>
                                    </Col>
                                    <Col span={12} style={{ height: '100%' }}>
                                        <Flex justify="end" align="baseline">
                                            <Typography.Link
                                                href='https://www.ex.co.kr/'
                                                target='_blank'
                                            >
                                                <Image
                                                    src={'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/contests/highway/logo.png'}
                                                    preview={false}
                                                    height={20}
                                                    style={{
                                                        objectFit: 'contain'
                                                    }}
                                                />
                                            </Typography.Link>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...{ xs: 24, sm: 24, md: 24, lg: 0, xl: 0, xxl: 0 }}>
                        <Row gutter={[12, 12]} style={{ marginTop: 32, marginBottom: 24, }}>
                            <Col span={24}>
                                <Typography.Link
                                    href='https://www.ex.co.kr/'
                                    target='_blank'
                                >
                                    <Flex justify='start'>
                                        <Image
                                            src={'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/contests/highway/logo.png'}
                                            preview={false}
                                            height={35}
                                            style={{
                                                maxWidth: 175,
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </Flex>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Space size={4} direction="vertical">
                                    <Typography.Text>
                                        <Typography.Link
                                            href="tel: 025383602"
                                        >
                                            <Typography.Text type='secondary'>
                                                +82 02.538.3602
                                            </Typography.Text>
                                        </Typography.Link>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <Typography.Text type='secondary'>
                                            contact@thinkingwolf.co.kr
                                        </Typography.Text>
                                    </Typography.Text>
                                    <Typography.Text type='secondary'>
                                        서울특별시 강남구 테헤란로 82길 15, 디아이타워 14층
                                    </Typography.Text>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Typography.Text type='secondary'>
                                    copyright © ThinkingWolf. All rights reserved.
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </footer>
    )
}