import { CloseOutlined } from '@ant-design/icons'
import { Col, Flex, Modal, Row, Typography, Image, Space } from "antd"
import { FunctionComponent } from "react"
import { VerificationModel } from '../model/VerificationModel'

interface IProps {
    reference: VerificationModel
    show: boolean
    onClosed: () => void
}

export const VerificationModal: FunctionComponent<IProps> = (props) => {
    const { reference, show, onClosed } = props

    return (
        <Modal
            open={show}
            closeIcon={false}
            maskClosable={true}
            destroyOnClose={true}
            maskTransitionName=""
            onCancel={() => { onClosed() }}
            footer={null}
            width={'60%'}
            styles={{
                content: {
                    padding: 0,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    maxWidth: 1400,
                    margin: '0 auto',
                }
            }}
            style={{ minWidth: 350 }}
        >
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <Flex justify='end'>
                        <Typography.Link
                            onClick={onClosed}
                        >
                            <CloseOutlined
                                style={{
                                    color: '#fff',
                                    fontSize: 26,
                                }}
                            />
                        </Typography.Link>
                    </Flex>
                </Col>
                <Col span={24}>
                    {reference.type === 'image' && (
                        <Flex align="center">
                            <Image
                                width="100%"
                                height="auto"
                                alt='대국민검증 이미지'
                                src={`https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/verifications/productions/%E1%84%92%E1%85%A1%E1%86%AB%E1%84%80%E1%85%AE%E1%86%A8%E1%84%83%E1%85%A9%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9%E1%86%BC%E1%84%89%E1%85%A1+AI+%E1%84%80%E1%85%A9%E1%86%BC%E1%84%86%E1%85%A9%E1%84%8C%E1%85%A5%E1%86%AB_${reference.id}(%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5).png`}
                            />
                        </Flex>
                    )}
                    {reference.type === 'video' && (
                        <Flex align="center">
                            <video
                                width="100%"
                                height="auto"
                                autoPlay
                                muted
                                loop
                                controls
                                playsInline
                                poster={`https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/verifications/thumbnails/AI+%E1%84%80%E1%85%A9%E1%86%BC%E1%84%86%E1%85%A9%E1%84%8C%E1%85%A5%E1%86%AB_${reference.id}.jpg`}
                            >
                                <source
                                    src={`https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/highways/verifications/productions/%E1%84%92%E1%85%A1%E1%86%AB%E1%84%80%E1%85%AE%E1%86%A8%E1%84%83%E1%85%A9%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9%E1%86%BC%E1%84%89%E1%85%A1+AI+%E1%84%80%E1%85%A9%E1%86%BC%E1%84%86%E1%85%A9%E1%84%8C%E1%85%A5%E1%86%AB_${reference.id}(%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC).${reference.extension ?? 'mp4'}`}
                                    type="video/mp4"
                                />
                                {'비디오 재생을 지원하지 않는 브라우저입니다 :('}
                            </video>
                        </Flex>
                    )}
                </Col>
                <Col span={24} style={{ marginTop: 24, }}>
                    <Space direction={'vertical'}>
                        <Typography.Title
                            level={4}
                            style={{ color: '#55c6ff', fontWeight: 700, textAlign: 'center', fontSize: 24, }}
                        >
                            {reference.name}
                        </Typography.Title>
                        {/* <Typography.Title
                            level={5}
                            style={{ color: '#fff', fontWeight: 700, textAlign: 'center', fontSize: 24, }}
                        >
                            {reference.author}
                        </Typography.Title> */}
                        <Space
                            direction='vertical'
                            style={{
                                borderTop: '1px solid #fff',
                                paddingTop: 12,
                                marginTop: 26,
                            }}
                        >
                            <Typography.Text style={{ color: '#fff', fontWeight: 700, fontSize: 18, }}>
                                {'기획 의도'}
                            </Typography.Text>
                            <Typography.Text style={{ whiteSpace: 'pre-wrap', color: '#fff', fontSize: 18, lineHeight: '28px' }}>
                                {reference.intention}
                            </Typography.Text>˝
                        </Space>
                        <Space
                            direction='vertical'
                            style={{
                                borderTop: '1px solid #fff',
                                paddingTop: 12,
                                marginTop: 8,
                            }}
                        >
                            <Typography.Text style={{ color: '#fff', fontWeight: 700, fontSize: 18, }}>
                                {'작품 설명'}
                            </Typography.Text>
                            <Typography.Text style={{ whiteSpace: 'pre-wrap', color: '#fff', fontSize: 18, lineHeight: '28px' }}>
                                {reference.description}
                            </Typography.Text>˝
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Modal>
    )
}