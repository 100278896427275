import { HomeOutlined, MenuOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Card, Col, Flex, Layout, message, Row, Space, Spin, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Errors } from '../contexts/ErrorContext'
import { NoticeModel } from '../model/NoticeModel'
import { NoticeService } from '../services/NoticeService'
import { MenuType } from '../type/MenuType'
import DateUtility from '../utils/DateUtility'
import NumberUtility from '../utils/NumberUtility'
import { Utilities } from '../utils/Utilities'
import { Header } from './layout/Header'
import { MainLayout } from './layout/MainLayout'

export const NoticeDetail: FunctionComponent = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    if (!id) {
        message.error("공지사항 상세정보를 찾을 수 없습니다.", 2, () => { navigate(-1) })
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [notice, setNotice] = useState<NoticeModel.INoticeModel | null>()

    const detail = async (id: string) => {
        setLoading(true)
        const response = await NoticeService.notice(id)
        if (response.status === 200) {
            setNotice(response.data.item)
        } else {
            Errors.AjaxError(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (id) {
            detail(id!!)
        }
    }, [id])

    return (
        <MainLayout menuType={MenuType.Notice}>
            <Header />
            <Row>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: '공지사항',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Spin spinning={isLoading}>
                    <Row gutter={[0, 16]} style={{ marginTop: 38, marginLeft: 0, marginRight: 0, }}>
                        <Col span={24}>
                            <Flex justify='end'>
                                <Button
                                    ghost
                                    type="primary"
                                    loading={isLoading}
                                    icon={<MenuOutlined />}
                                    onClick={() => { navigate('/notices') }}>
                                    목록보기
                                </Button>
                            </Flex>
                        </Col>
                        <Col span={24}>
                            <Row
                                gutter={[8, 8]}
                                justify={'center'}
                                style={{
                                    paddingTop: 18,
                                    paddingRight: 12,
                                    paddingLeft: 12,
                                    paddingBottom: 12,
                                    backgroundColor: '#f4faff',
                                    borderTop: '2px solid #1c79bf',
                                    marginLeft: 0, marginRight: 0,
                                }}
                            >
                                <Col span={24}>
                                    <Flex justify='center'>
                                        <Typography.Title level={4} style={{ textAlign: 'center' }}>{notice?.title}</Typography.Title>
                                    </Flex>
                                </Col>
                                <Col span={24}>
                                    <Flex justify='end'>
                                        <Space split={'|'}>
                                            <Typography.Text>운영 사무국</Typography.Text>
                                            <Typography.Text>{`조회: ${NumberUtility.comma(notice?.viewCount ?? 0)}`}</Typography.Text>
                                            <Typography.Text>{DateUtility.date_format(notice?.created)}</Typography.Text>
                                        </Space>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Card
                                bordered
                                size='small'
                            >
                                <div className="ql-editor">
                                    <div dangerouslySetInnerHTML={{ __html: notice?.content ?? '' }}></div>
                                </div>

                            </Card>

                        </Col>
                        {(notice?.files ?? [])?.length > 0 && (
                            <Col span={24}>
                                <Space direction='vertical'>
                                    <Typography.Text strong>{'[첨부파일]'}</Typography.Text>
                                    {notice?.files.map((f) => {
                                        return (
                                            <Space size={4}>
                                                <Typography.Link
                                                    strong
                                                    href={f.url}
                                                    target='_blank'
                                                >
                                                    {`${f.filename}`}
                                                </Typography.Link>
                                                <Typography.Text type='secondary'>{`(${Utilities.convertBytes(f.size ?? 0)?.fit})`}</Typography.Text>
                                            </Space>
                                        )
                                    })}
                                </Space>
                            </Col>
                        )}
                    </Row>
                </Spin>
            </Layout.Content>
        </MainLayout>
    )
}