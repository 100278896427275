import { FlagOutlined, HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Col, Flex, Form, Input, Layout, Row, Space, Spin, Table, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Errors } from '../contexts/ErrorContext'
import { NoticeModel } from '../model/NoticeModel'
import { PaginationModel } from '../model/PaginationModel'
import { NoticeService } from '../services/NoticeService'
import { MenuType } from '../type/MenuType'
import DateUtility from '../utils/DateUtility'
import NumberUtility from '../utils/NumberUtility'
import { Header } from './layout/Header'
import { MainLayout } from './layout/MainLayout'

export const Notice: FunctionComponent = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<PaginationModel<NoticeModel.INoticeModel> | null>(null)

    const search = (page: number = 1) => {
        setLoading(true)
        form.validateFields().then(async (values) => {
            const response = await NoticeService.search({
                ...values,
                page: page
            })
            if (response.status === 200) {
                setPage(response.data.item)
            } else {
                Errors.AjaxError(response.data)
            }
        })
            .catch((e) => {
                console.log('error', e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        search()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <MainLayout menuType={MenuType.Notice}>
            <Header />
            <Row>
                <Col
                    span={24}
                    style={{
                        borderBottom: '1px solid #eee',
                        height: 50,
                    }}
                >
                    <Layout.Content className='inner-container responsive-container' style={{ height: '100%' }}>
                        <Flex justify='start' align='center' style={{ height: '100%' }}>
                            <Breadcrumb
                                separator=">"
                                items={[
                                    {
                                        title: (
                                            <Typography.Link
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <HomeOutlined />
                                            </Typography.Link>
                                        )
                                    },
                                    {
                                        title: '공지사항',
                                    },
                                ]}
                            />
                        </Flex>
                    </Layout.Content>
                </Col>
            </Row>
            <Layout.Content className='inner-container responsive-container'>
                <Row gutter={[8, 8]} style={{ marginTop: 12, marginLeft: 0, marginRight: 0, }}>
                    <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Typography.Title level={3}>공지사항</Typography.Title>
                    </Col>
                    {/* <Col span={24} style={{ padding: 0, marginTop: 24, }}>
                        <Flex justify='end'>
                            <Space>
                                <Form form={form}>
                                    <Form.Item
                                        noStyle
                                        name={'keyword'}
                                    >
                                        <Input
                                            allowClear
                                            placeholder={"검색어를 입력하세요"}
                                            onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                if ((e.key == "Enter" || e.keyCode === 13)) {
                                                    search()
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Form>
                                <Button type="primary" ghost loading={isLoading} onClick={() => { search() }}>검색</Button>
                            </Space>
                        </Flex>
                    </Col> */}
                    <Col span={24}>
                        <Row justify={'center'}>
                            <Col span={24}>
                                <Spin spinning={isLoading}>
                                    <Table
                                        rowKey={record => record.id}
                                        size='small'
                                        title={() => (
                                            <Row>
                                                <Col span={12} style={{ textAlign: 'start' }}>
                                                    <Flex align='end' style={{ height: '100%' }}>
                                                        <Typography.Text strong>{`검색된 결과 총 ${page?.totalItemCount ?? 0}개`}</Typography.Text>
                                                    </Flex>
                                                </Col>
                                                <Col span={12}>
                                                    <Flex justify='end'>
                                                        <Space>
                                                            <Form form={form}>
                                                                <Form.Item
                                                                    noStyle
                                                                    name={'keyword'}
                                                                >
                                                                    <Input
                                                                        allowClear
                                                                        placeholder={"검색어를 입력하세요"}
                                                                        onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                            if ((e.key === "Enter" || e.keyCode === 13)) {
                                                                                search()
                                                                            }
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Form>
                                                            <Button type="primary" ghost loading={isLoading} onClick={() => { search() }}>검색</Button>
                                                        </Space>
                                                    </Flex>
                                                </Col>
                                            </Row>
                                        )}
                                        loading={isLoading}
                                        pagination={{
                                            defaultCurrent: 1,
                                            position: ['bottomCenter'],
                                            current: page?.currentPageIndex,
                                            total: page?.totalItemCount,
                                            pageSize: page?.size,
                                            showSizeChanger: false,
                                            onChange: async (page: number) => {
                                                search(page)
                                            }
                                        }}
                                        locale={{
                                            emptyText: '공지사항이 존재하지 않습니다.'
                                        }}
                                        rowClassName={(r) => {
                                            return r.isHighlights ? 'highlight' : ''
                                        }}
                                        dataSource={page ? page.items : []}
                                        columns={[
                                            {
                                                title: "번호",
                                                align: 'center',
                                                width: 80,
                                                render: (_, record: NoticeModel.INoticeModel, index: number) => {
                                                    if (record.isHighlights) {
                                                        return <FlagOutlined style={{ fontSize: 18, color: '#1c99f5' }} />
                                                        // return <NotificationTwoTone twoToneColor={'#1c99f5'} style={{ fontSize: 18, }} />
                                                    }

                                                    return (
                                                        <Typography.Text>{(page?.totalItemCount ?? 0) - (index + (((page?.currentPageIndex ?? 0) - 1) * (page?.size ?? 0)))}</Typography.Text>
                                                    )
                                                }
                                            },
                                            {
                                                title: "제목",
                                                dataIndex: "title",
                                                key: "title",
                                                align: 'center',
                                                ellipsis: true,
                                                render: (title: string, record: NoticeModel.INoticeModel) => {
                                                    return (
                                                        <Link to={`/notices/${record.id}`}>
                                                            {title}
                                                        </Link>
                                                    )
                                                }
                                            },
                                            {
                                                title: "작성자",
                                                align: 'center',
                                                responsive: ['lg'],
                                                width: 140,
                                                render: () => <Typography.Text>운영 사무국</Typography.Text>
                                            },
                                            {
                                                width: 140,
                                                title: "조회수",
                                                key: "viewCount",
                                                align: 'center',
                                                dataIndex: "viewCount",
                                                responsive: ['lg'],
                                                render: (count: number) => <Typography.Text>{NumberUtility.comma(count ?? 0)}</Typography.Text>
                                            },
                                            {
                                                width: 120,
                                                title: "생성일",
                                                key: "created",
                                                align: 'center',
                                                dataIndex: "created",
                                                render: date => DateUtility.date_format(date)
                                            },
                                        ]}
                                    />
                                </Spin>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}