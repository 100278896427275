import axios, { AxiosError, AxiosRequestConfig } from "axios"

const instance = axios.create({
    baseURL: 'https://admin.thinkingwolf.co.kr/api/',
    // baseURL: 'http://localhost:3000/api/',
    timeout: 60000,
    withCredentials: false,
    responseType: "json",
    headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json; charset:UTF-8'
    },
} as AxiosRequestConfig)

// instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
//     config.headers.Accept = 'application/json'
//     return config
// })

instance.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response) {
        const { status, data, config } = error.response
        // if (status === 400) {
        //     console.log("400", data, config)

        //     const error = (data as any)?.errors
        //     notification.error({
        //         message: error[Object.keys(error)[0]]
        //     },)
        // }

        if (status === 403) {
            console.log("403", data, config)
        }

        if (status === 500) {
            console.log("500", data, config)
        }
    }

    return error.response
})

export default instance