import { VerticalAlignTopOutlined } from '@ant-design/icons'
import { FloatButton, Layout } from "antd"
import { FunctionComponent, ReactNode, useEffect, useState } from "react"
import { MenuType } from "../../type/MenuType"
import { Footer } from "./Footer"
import { Navigation } from "./Navigation"

interface IProps {
    menuType?: MenuType
    children?: ReactNode
}

export const MainLayout: FunctionComponent<IProps> = (props: IProps) => {
    const { menuType, children } = props
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleNavbar)
        return () => {
            window.removeEventListener("scroll", handleNavbar)
        }
    }, [])

    const handleNavbar = () => {
        window.scrollY > 150 ? setShow(true) : setShow(false)
    }

    return (
        <Layout.Content>
            <Navigation menuType={menuType} />
            {children}
            <Footer />
            <FloatButton.Group shape="circle" style={{ opacity: show ? 1 : 0 }}>
                <FloatButton
                    icon={<VerticalAlignTopOutlined style={{ color: '#0088CC' }} />}
                    onClick={() => {
                        window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
                    }}
                />
            </FloatButton.Group>
        </Layout.Content>
    )
}